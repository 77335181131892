import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import {
  ButtonPrimary
} from 'components/Button'

const FlexTitleCtaItem = styled(Link)`
  position: relative;
  display: block;

  &:after {
    display: ${p => p.$titleFormat === 'Center - Button' ? 'none' : 'block'};
    content: '';
    background-color: rgba(0,0,0,.2);
    opacity: 0;
    transition: opacity 600ms cubic-bezier(0.25, 0.1, 0.25, 1);
    ${p => p.theme.mixins.fill('absolute')}
    z-index: 1;
  }

  &:hover:after {
   opacity: 1;
  }
`

const ImageWrapper = styled.div`
  .gatsby-image-wrapper-constrained {
    display: block;
    [data-main-image],
    [data-placeholder-image] {
      transition: none;
      opacity: 1;
    }
  }

  ${p => p.theme.media.minWidth('tablet')} {
    display: ${p => p.isMobile ? 'none' : 'block'};
  }

  & + div {
    ${p => p.theme.media.maxWidth('tablet')} {
      display: ${p => p.isMobile ? 'none' : 'block'};
    }    
  }
`

const Title = styled.h2`
  position: absolute;
  margin: 0;
  text-align: center;
  z-index: 2;

  ${p => {
    switch ( p.format ) {     
      case 'Center':
      return p.theme.mixins.center('absolute')
      case 'Bottom':
      return 'bottom: 0;'
      default:
        return
    }
  }}

  ${p => {
    switch ( p.format ) {
      case 'Bottom':
      return p.theme.typography.tMedium
      case 'Center':
      return p.theme.typography.tH1
      default:
        return
    }
  }}

  width: ${p => {
    switch ( p.format ) {
      case 'Bottom': 
      case 'Center': 
      return '100%'
      default:
      return 'auto'
    }
  }};

  padding: ${p => {
    switch ( p.format ) {
      case 'Bottom': 
      case 'Center': 
      return '7px'
      default:
        return 0
    }
  }};

  background-color: ${p => {
    switch ( p.format ) {
      case 'Bottom':
      return p.theme.colors.xxdkGreen
      default:
        return 'transparent'
    }
  }};   
`

const TitleButton = styled(ButtonPrimary)`
  ${p => p.theme.mixins.center('absolute')}
  z-index: 2;

  ${p => p.theme.media.minWidth('tablet')} {
    width: 200px;
  }
`

const Component = (props) => {
  const renderMobileImage = () => {
    if ( !props.mobileImage ) return

    return (
      <ImageWrapper isMobile>
        <GatsbyImage          
            image={getImage(props.mobileImage)}
            alt={props.mobileImage.title || ''} />
      </ImageWrapper>
    )
  }


  const renderTitle = () => {
    if ( props.titleFormat === 'Center - Button' ) {
      return (
        <TitleButton hoverTrigger={FlexTitleCtaItem}>
          {props.title}
        </TitleButton>
      )
    } else {
      return (
        <Title format={props.titleFormat}>
          {props.title}
        </Title>
      )      
    }    
  }
  
  return (
    <FlexTitleCtaItem
        to={props.link}
        $titleFormat={props.titleFormat}>

      {renderMobileImage()}
      
      <ImageWrapper>
        <GatsbyImage
            image={getImage(props.image)}
            alt={props.image.title || ''} />
      </ImageWrapper>            

      {renderTitle()}
    </FlexTitleCtaItem>
  )
}

export const query = graphql`
   fragment FlexTitleCtaItem on ContentfulFlexTitleCtaItem {
     id
     title
     titleFormat
     link
     image {
       title
       gatsbyImageData
     }
     mobileImage {
       title
       gatsbyImageData(layout: FULL_WIDTH)
     }
   }  
 `

export default Component
