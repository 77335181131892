import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import AnimationManager from 'components/AnimationManager'

import {
  Paragraph,
  ButtonLink
} from 'components/Typography'

const CtaBanner = styled.div`
  position: relative;
  height: 210px;
  margin: 10px 0;
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${p => p.theme.media.minWidth('tablet')} {
    height: 240px;
    margin: ${p => p.theme.grid.columnGutter.tablet}px 0;
  }
`

const Image = styled(GatsbyImage)`
  ${p => p.theme.mixins.fill('absolute')}
  position: absolute !important;
`

const Content = styled.div`
  ${p => p.theme.mixins.center('absolute')}
  color: ${p => p.theme.colors.white};
  text-align: center;
`

const Title = styled.h2`
  margin: 0;
  text-transform: uppercase;
  font-size: 50px;
  letter-spacing: .075em;
`

const Cta = styled.div`
  margin: 30px 0 0 0;
`

const Component = ( props ) => {
  const image = getImage(props.backgroundImage)
  
  const renderCtaButton = () => {
    if ( !props.showCtaLink ) return

    return (
      <Cta>
        <ButtonLink to={props.ctaLinkUrl}>
          {props.ctaLinkText}
        </ButtonLink>
      </Cta>
    )
  }
  
  return (
    <CtaBanner>
      <AnimationManager>
        <div className='u-fade-reveal'>
          <Image
              image={image}
              alt={image.title || ''} />

          <Content>
            <Title>{props.title}</Title>
            <Paragraph>{props.description.description}</Paragraph>
            {renderCtaButton()}
          </Content>
        </div>
      </AnimationManager>
    </CtaBanner>
  )
}

export const query = graphql`
  fragment CtaBanner on ContentfulModuleCtaBanner {
    id
    title
    description {
      description
    }
    showCtaLink
    ctaLinkText
    ctaLinkUrl
    backgroundImage {
      title
      gatsbyImageData
    }    
  }
`

export default Component
