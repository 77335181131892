import React, { useEffect, useState } from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import gsap from 'gsap'

const AnimationManager = ({
  children,
  ...props
}) => {
  const [enabled, setEnabled] = useState(false)
  
  useEffect(() => {        
    setTimeout(() => {
      setEnabled(true)
    }, 100)    
  }, [])
  
  useEffect(() => {
    /* gsap.set(.filter(e => !!e), {
     *   autoAlpha: 0
     * })*/
  })
  
  const onStart = (progress, e) => {
    const children = e.target.triggerElement().querySelectorAll('.u-fade-reveal')
    
    if ( progress === 1 )
      gsap.fromTo(children, 2, {
        autoAlpha: 0
      }, {
        autoAlpha: 1,
        stagger: .12,
        ease: 'power4.out'
      })
  }
  
  return (
    <Controller>
      <Scene duration={0} reverse={false} triggerHook={1} enabled={enabled}>
        {(progress, event) => {
           switch ( event.type || event ) {
             case 'start':
               onStart(progress, event)
               break
             default:
               
           }
           
           return (<div>{children}</div>)
         }}
      </Scene>
    </Controller>
  )  
}

export default AnimationManager

