import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Breakpoint } from 'react-socks'

import {
  ParagraphXLarge
} from 'components/Typography'

import RichText from 'components/RichText'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

const MediaTextWrapper = styled.div`
  position: relative;
  margin: 24px 0;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 50px 0;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    margin: 75px 0;
  }
`

const Headline = styled(ParagraphXLarge)`
  margin: 0 0 1.5em 0;
`

const Media = styled.div`
  margin: 0 0 24px 0;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 0;
  }
`

const MediaText = ({
  image,
  imagePosition,
  headline,
  body,
  ...props
}) => {  
  return (
    <MediaTextWrapper>
      <Container>
        <Row>
          <Col lg={20} lgPush={2}>
            <Row
                dkGutterFactor={2}
                lgGutterFactor={3}
                tbFlexDir={imagePosition === 'Left' ? 'row' : 'row-reverse'}
                dkAlignItems='center'>
              <Col tb={12}>
                <Breakpoint mobile-large down>
                  <Headline>
                    {headline.headline}
                  </Headline>
                </Breakpoint>
                <Media>
                  <GatsbyImage
                      image={getImage(image)}
                      alt={image.title || ''} />
                </Media>
              </Col>
              <Col tb={12}>
                <Breakpoint tablet up>
                  <Headline>
                    {headline.headline}
                  </Headline>
                </Breakpoint>
                
                <RichText body={body}/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </MediaTextWrapper>
  )
}

export const query = graphql`
  fragment MediaText on ContentfulModuleMediaText {
    id   
    image {
      title
      gatsbyImageData(     
        layout: FULL_WIDTH
      )
    }
    imagePosition
    headline {
      headline
    }
    body {
      raw
    }
  }
`

export default MediaText
