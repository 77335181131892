import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

const MediaWrapper = styled.div`
  position: relative;
  margin: 24px 0;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 50px 0;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    margin: 75px 0;
  }
`

const MediaContainer = styled(Container)`
  ${p => p.fullBleed && 'padding: 0 !important'};
`

const DesktopImage = styled.div`
  position: relative;
`

const MobileImage = styled.div`
  & + ${DesktopImage} {
    display: none;
  }

  ${p => p.theme.media.minWidth('tablet')} {
    display: none;

    & + ${DesktopImage} {
      display: block;
    }
  }
`

const Media = ({
  image,
  mobileImage,
  width
}) => {
  return (
    <MediaWrapper>
      <MediaContainer fullBleed={width === 'Full Bleed'}>
        <Row>
          <Col
              lg={width === 'Full Bleed' ? 24 : 20}
              lgPush={width === 'Full Bleed' ? 0 : 2}
          >
            {mobileImage && (
               <MobileImage>
                 <GatsbyImage
                     image={getImage(mobileImage)}
                     alt={mobileImage.title || ''} />           
               </MobileImage>
             )}
            <DesktopImage>
              <GatsbyImage
                  image={getImage(image)}
                  alt={image.title || ''} />
            </DesktopImage>
          </Col>
        </Row>
      </MediaContainer>
    </MediaWrapper>
  )
}

export const query = graphql`
  fragment Media on ContentfulModuleMedia {
    id
    width
    image {
      title
      gatsbyImageData(     
        layout: FULL_WIDTH
      )
    }
    mobileImage {
      title
      gatsbyImageData(    
        layout: FULL_WIDTH
      )
    }
  }
`

export default Media
