import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import {
  H1,
  Paragraph
} from 'components/Typography'

import {
  ButtonSecondary
} from 'components/Button'

const CtaItem = styled(Link)`
  display: block;
  position: relative;
  margin: 0 0 10px 0;  

  ${p => p.theme.media.maxWidth('tablet')} {
    ${p => p.$noMarginMobile && 'margin 0 !important;'};
  }

  ${p => p.theme.media.minWidth('tablet')} {
    ${p => p.$noMarginTablet && 'margin 0 !important;'};
  }

  ${p => p.$noMargin && 'margin: 0 !important'};

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 0 0 ${p => p.theme.grid.columnGutter.tablet}px 0;;
  }
`

const ImageWrapper = styled.div`
  .gatsby-image-wrapper-constrained [data-main-image] {
    transition: none;
    opacity: 1;
  }

  .gatsby-image-wrapper-constrained {
    display: block;
  }
  
  &:after {
    content: "";
    background-color: rgba(0,0,0,.2);
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.35));
    opacity: 1;
    ${p => p.theme.mixins.fill('absolute')}    
  }

  ${p => p.theme.media.minWidth('tablet')} {
    display: ${p => p.isMobile ? 'none' : 'block'};
  }

  & + div {
    ${p => p.theme.media.maxWidth('tablet')} {
      display: ${p => p.isMobile ? 'none' : 'block'};
    }    
  }
`

const TextWrapper = styled.div`
  ${p => p.theme.mixins.hCenter('absolute')};
  width: 100%;
  max-width: 500px;
  bottom: 25px;
  padding: 0 ${p => p.theme.grid.containerPadding.mobile}px;
  z-index: 2;
  text-align: center;

  h2 {
    margin: 0 0 15px 0;
    ${p => p.theme.media.minWidth('tablet')} {
      margin: 0 0 10px 0;      
    }
  }

  p {
    margin: 0 0 15px 0;
    ${p => p.theme.media.minWidth('tablet')} {
      margin: 0 0 20px 0;      
    }
  }
`

const Component = (props) => {
  const renderCtaButton = () => {
    if ( !props.showCtaButton ) return

    return (
      <ButtonSecondary hoverTrigger={CtaItem}>
        {props.ctaButtonText}
      </ButtonSecondary>
    )
  }

  const renderMobileImage = () => {
    if ( !props.mobileImage ) return

    return (
      <ImageWrapper isMobile>
        <GatsbyImage          
            image={getImage(props.mobileImage)}
            alt={props.mobileImage.title || ''} />
      </ImageWrapper>
    )
  }
  
  return (
    <CtaItem
        $noMarginMobile={props.noMarginMobile}
        $noMarginTablet={props.noMarginTablet}
        to={props.link}>
      
      {renderMobileImage()}
      <ImageWrapper>
        <GatsbyImage
          image={getImage(props.image)}
          alt={props.image.title || ''} />
      </ImageWrapper>      

      <TextWrapper>
        <H1 as='h2'>{props.title}</H1>        
        <Paragraph>{props.description}</Paragraph>
        {renderCtaButton()}
      </TextWrapper>
    </CtaItem>
  )
}

export const query = graphql`
   fragment CtaItem on ContentfulCtaItem {
     id
     title
     description
     link
     showCtaButton
     ctaButtonText
     image {
       title
       gatsbyImageData
     }
     mobileImage {
       title
       gatsbyImageData(layout: FULL_WIDTH)
     }
   }  
 `

export default Component
