import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import AnimationManager from 'components/AnimationManager'
import CtaItem from 'components/modules/CtaItem'

import {
  Container
} from 'components/Grid'

const CtaSolo = styled.div`
  position: relative;
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${p => p.theme.media.minWidth('tablet')} {
    margin: ${p => p.theme.grid.columnGutter.tablet}px 0;
  }
`

const Component = ({ item }) => {  
  return (
    <CtaSolo>
      <AnimationManager>
        <div className='u-fade-reveal'>
          <Container fullBleedMobile={true}>
            <CtaItem {...item} isLast={true} />
          </Container>
        </div>
      </AnimationManager>
    </CtaSolo>
  )
}

export const query = graphql`
  fragment CtaSolo on ContentfulModuleCtaSolo {
    id
    item {
      ...CtaItem
    }    
  }
`

export default Component
