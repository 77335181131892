import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import CtaItem from 'components/modules/CtaItem'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

const CtaGridWrapper = styled.div`
  position: relative;
  margin: 10px 0; 

  ${p => p.theme.media.minWidth('tablet')} {
    margin: ${p => p.theme.grid.columnGutter.tablet}px 0;
  }
`

const CtaGrid = ({
  items
}) => {  
  return (
    <CtaGridWrapper>      
      <Container fullBleedMobile={true}>
        <Row>
          {items.map((item, i) => {
             return (
               <Col mb={24} tb={8} key={i}>
                 <CtaItem
                     noMarginMobile={i === items.length - 1}
                     noMarginTablet={i >= items.length - 3}
                     {...item} />
               </Col>
             )
           })}
        </Row>
      </Container>
    </CtaGridWrapper>
  )
}

export const query = graphql`
  fragment CtaGrid on ContentfulModuleCtaGrid {
    id    
    items {
      ...CtaItem
    }    
  }
`

export default CtaGrid
