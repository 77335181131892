import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Slider from 'react-slick'

import ProductCard from 'components/product/Card'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

import {
  H1,
  Paragraph
} from 'components/Typography'

import {
  ButtonPrimaryLink
} from 'components/Button'

const FeaturedProductsWrapper = styled.div`
  margin: 30px 0;
  text-align: center;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 40px 0;
  }
`

const Header = styled.div`
  margin: 0 auto 30px auto;
  text-align: center;
  max-width: 365px;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 0 auto 40px auto;
  }
  
  h2 {
    margin: 0 0 .75em 0;
  }

  p {
    margin: 0;
  }
`

const SliderContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;

  ${p => p.theme.media.minWidth('tablet')} {
    display: none;
  }

  .slick-list {
    padding-left: ${p => p.theme.grid.columnGutter.mobile / 2}px;
  }
`

const GridContainer = styled(Container)`
  ${p => p.theme.media.maxWidth('tablet')} {
    display: ${p => p.hideMobile ? 'none': 'block'};
  }
`

const ProductWrapper = styled.div`
  padding: 0 ${p => p.theme.grid.columnGutter.mobile / 2}px;

  > div {
    width: 60vw;
  }    
`

const CtaButton = styled(ButtonPrimaryLink)`  
  width: 100%;
  
  ${p => p.theme.media.minWidth('mobile-large')} {
    max-width: 250px;
  }  

  ${p => p.theme.media.minWidth('tablet')} {
    margin-top: 40px;
  }  
`

const FeaturedProducts = (props) => {  
  const settings = {
    arrows: false,
    variableWidth: true
  } 
  
  const {
    title,
    description,
    mobileLayout,
    products,
    showCtaButton,
    ctaButtonLink,
    ctaButtonText
  } = props
  
  return (
    <FeaturedProductsWrapper>
      <Container>
        <Header>
          <H1 as='h2'>{title}</H1>
          {description && (
            <Paragraph>{description.description}</Paragraph>
           )}          
        </Header>
      </Container>
      
      {mobileLayout === 'Carousel' &&
       <SliderContainer>
         <Slider {...settings}>
           {products.map((product, i) => (
              <ProductWrapper key={i}>
                <ProductCard {...product} noMargin />
              </ProductWrapper>
            ))}
         </Slider>
       </SliderContainer>}
      

      <GridContainer hideMobile={mobileLayout === 'Carousel'}>
        <Row>
          {products.map((product, i) => (             
             <Col mb={12} tb={6} dk={6} key={i}>
               <ProductCard {...product} noMargin={i >= (products.length - 4)} />
             </Col>           
           ))}          
        </Row>

        {showCtaButton && (
           <CtaButton to={ctaButtonLink}>{ctaButtonText}</CtaButton>
         )}
      </GridContainer>
    </FeaturedProductsWrapper>
  )
}

export const query = graphql`
  fragment FeaturedProducts on ContentfulModuleFeaturedProducts {
    id
    title
    description {
      description
    }
    mobileLayout
    showCtaButton
    ctaButtonText
    ctaButtonLink
    shopifyVariantIds
  }
`

export default FeaturedProducts
