import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import CtaItem from 'components/modules/CtaItem'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

import {
  H1
} from 'components/Typography'

const CtaTrioWrapper = styled.div`
  position: relative;
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${p => p.theme.media.minWidth('tablet')} {
    margin: ${p => p.theme.grid.columnGutter.tablet}px 0;
  }
`

const Title = styled(H1)`
  text-align: center;
  margin: 0 0 1.5em 0;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 3em 0 2em 0;
  }
`

const CtaTrio = ({
  title,
  displayTitle,
  items
}) => {  
  return (
    <CtaTrioWrapper>
      {displayTitle && (
         <Container>
           <Title as='h2'>{title}</Title>
         </Container>
       )}
      <Container fullBleedMobile={true}>
        <Row>
          {items.map((item, i) => {
             return (
               <Col mb={24} tb={8} key={i}>
                 <CtaItem
                     noMarginMobile={i === items.length - 1}
                     noMarginTablet={i >= items.length - 3}
                     {...item} />
               </Col>
             ) 
           })}
        </Row>
      </Container>
    </CtaTrioWrapper>
  )
}

export const query = graphql`
  fragment CtaTrio on ContentfulModuleCtaTrio {
    id
    title
    displayTitle
    items {
      ...CtaItem
    }    
  }
`

export default CtaTrio
