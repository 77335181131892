export default function decodeBase64 (input)  {
  const base64Chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  let decoded = "";
  let padding = 0;
  let buffer = 0;
  let position = 0;

  for (let i = 0; i < input.length; i++) {
    const char = input.charAt(i);
    const index = base64Chars.indexOf(char);
    if (index === -1) continue;

    buffer = (buffer << 6) | index;
    padding += char === "=" ? 1 : 0;
    position += 6;

    if (position >= 8) {
        position -= 8;
        decoded += String.fromCharCode((buffer >> position) & 0xff);
    }
  }

  return decoded.replace(/[\x10@]/g, '');
}
