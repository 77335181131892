import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import AnimationManager from 'components/AnimationManager'
import CtaItem from 'components/modules/CtaItem'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

const CtaDuo = styled.div`
  position: relative;
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${p => p.theme.media.minWidth('tablet')} {
    margin: ${p => p.theme.grid.columnGutter.tablet}px 0;
  }
`

const Component = ({ items }) => {  
  return (
    <CtaDuo>
      <AnimationManager>
        <Container fullBleedMobile={true}>
          <Row>
            {items.map((item, i) => (
               <Col tb={12} key={item.id}>
                 <div className='u-fade-reveal'>
                   <CtaItem {...item} isLast={i === 1}/>
                 </div>
               </Col>
             ))}
          </Row>
        </Container>
      </AnimationManager>
    </CtaDuo>
  )
}

export const query = graphql`
  fragment CtaDuo on ContentfulModuleCtaDuo {
    id
    title
    items {
      ...CtaItem
    }    
  }
`

export default Component
