import React from 'react'
import { graphql } from 'gatsby'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

import RichText from 'components/RichText'

const Text = ({
  body,
  width
}) => {

  return (
    <Container>
      <Row>
        <Col
            tb={width === 'Narrow' ? 20 : 24}
            tbPush={width === 'Narrow' ? 2 : 0}
            dk={width === 'Narrow' ? 18 : 18}
            dkPush={width === 'Narrow' ? 3 : 3}
            lg={width === 'Narrow' ? 14 : 18}
            lgPush={width === 'Narrow' ? 5 : 3}
            xl={width === 'Narrow' ? 14 : 16}
            xlPush={width === 'Narrow' ? 5 : 4}
            xxl={width === 'Narrow' ? 12 : 16}
            xxlPush={width === 'Narrow' ? 6 : 4}
        >
          <RichText body={body}/>
        </Col>
      </Row>
    </Container>    
  )
}

export const query = graphql`
  fragment Text on ContentfulModuleText {
    id 
    width
    body {
      raw
    }
  }
`

export default Text
