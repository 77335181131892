import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import styled, { ThemeContext } from 'styled-components'
import { flow, get } from 'lodash/fp'

import CtaBanner from 'components/modules/CtaBanner'
import CtaSplit from 'components/modules/CtaSplit'
import CtaSolo from 'components/modules/CtaSolo'
import CtaDuo from 'components/modules/CtaDuo'
import CtaTrio from 'components/modules/CtaTrio'
import CtaGrid from 'components/modules/CtaGrid'
import FeaturedProducts from 'components/modules/FeaturedProducts'
import Media from 'components/modules/Media'
import MediaText from 'components/modules/MediaText'
import Text from 'components/modules/Text'

import Head from 'components/Head'
import PageHeader from 'components/PageHeader'

import Fade from 'transitions/Fade'
import decodeBase64 from '../utils/decodeBase64';

const Components = {
  CtaBanner,
  CtaSplit,
  CtaSolo,
  CtaDuo,
  CtaTrio,
  CtaGrid,
  FeaturedProducts,
  Media,
  MediaText,
  Text
}

const Modules = styled.div`
  & > * {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
`

const ModularPage = ({
  transitionStatus,
  entry,
  exit,
  pageContext,
  ...props
}) => {
  // get all Contentful data from gql
  const page = flow(
    get('data.contentful.nodes[0]')
  )(props)

  // get all Shopify products from gql
  const products = flow(
    get('data.shopify.nodes')
  )(props)

  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.global.setHeaderPadding(!page.displayPageHeader)
  }, [page.displayPageHeader, theme.global])

 
  return (
    <Fade>
      <Head
        title={page.title}
        description={get('seoDescription.seoDescription')(page)}
        image={get('seoImage.default.images.fallback.src')(page)}
      />
      {page.displayPageHeader && (
        <PageHeader
          title={page.title}
          description={page.description}
          layout={page.pageHeaderLayout} />
      )}
      <Modules>
        {page.modules.map((module, i) => {
          // @TODO make this a utility
          if (module.shopifyVariantIds) {
            const _products = module.shopifyVariantIds.map(id => {
              return products.find(p => {
                return p.variants.map(v => v.storefrontId).includes(decodeBase64(id))
              })
            }).filter(p => !!p)

            module.products = _products
          }

          const Component = Components[
            module.__typename.replace('ContentfulModule', '')
          ]

          return <Component key={i} {...module} />
        })}
      </Modules>
    </Fade>
  )
}

export const query = graphql`
  query ModularPageQuery(
    $pageId: String!, 
    $productIds: [String!]
   ) {
     contentful: allContentfulPageModular(
       limit: 1, 
       filter: { 
         id: { 
           eq: $pageId
         }
       }
     ) {
       nodes {
         ...HeadPageModular
         title
         description {
           raw
         }
         
         displayPageHeader
         pageHeaderLayout
         modules {
           __typename
           ...CtaBanner
           ...CtaSplit
           ...CtaSolo
           ...CtaDuo
           ...CtaTrio
           ...CtaGrid
           ...FeaturedProducts
           ...Media
           ...MediaText
           ...Text
         }
       }
     }    

     shopify: allShopifyProduct(
       filter: {
         storefrontId: {
           in: $productIds
         }
       }
     ) {
       nodes {
         ...Product
       }
     }
   }   
 `

export default ModularPage
