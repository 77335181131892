import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import {
  Container
} from 'components/Grid'

import {
  H1
} from 'components/Typography'

const Image = styled.div`  
  flex: 1;
  z-index: 1;

  ${p => p.theme.media.maxWidth('tablet')} {
    ${p => p.theme.mixins.fill('absolute')};
  }

  ${p => p.theme.media.minWidth('tablet')} {
    position: relative;
    width: auto;
    height: auto;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .gatsby-image-wrapper [data-main-image] {
    transition: none;
    opacity: 1;
  }
  
  .gatsby-image-wrapper {
    display: block;
    min-height: 100%;

    ${p => p.theme.media.maxWidth('tablet')} {
      ${p => p.theme.mixins.fill('absolute')};   
    }
  }
`

const Copy = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  padding: calc(${p => p.theme.header.mobileHeight} + 30px) 0 30px 0;
  z-index: 2;

  ${p => p.theme.media.minWidth('tablet')} {
    padding: calc(${p => p.theme.header.tabletHeight} + 30px) 0 50px 0;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    padding: calc(${p => p.theme.header.tabletHeight} + 50px) 0 75px 0;
  }
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;  
  margin: 0 0 ${p => p.noMargin ? 0 : '40px'} 0;
  background-color: ${p => p.layout === 'Hero' ? p.theme.colors.dkGreen : 'transparent'};  

  & > ${Image} {
    display: ${p => p.layout === 'Hero' ? 'block': 'none'};
  }

  & > ${Copy} {
    ${p => p.layout !== 'Hero' && 'padding-bottom: 24px !important'};
  }
`

const Title = styled(H1)`
  margin: 0 0 .75em 0;
  
  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 42px;
  }
  
`

const CopyInner = styled(Container)`
  max-width: 550px;
`

const Description = styled.div`
  ${p => p.theme.typography.tSmall};

  ${p => p.theme.media.minWidth('tablet')} {
    ${p => p.theme.typography.tNormal};
  }
`

const PageHeader = ({
  title,
  description,
  image,
  layout,
  noMargin
}) => {

  const renderImage = () => {
    if ( !image ) return    
    
    return (
      <Image>
        <GatsbyImage
            image={getImage(image)}
            alt=''/>
      </Image>
    )
  }

  const renderDescription = () => {
    if ( !description ) return 
    if ( description.raw ) {
      return (
        <Description>
          {renderRichText(description)}
        </Description>
      )
    } else {
      return (
        <Description dangerouslySetInnerHTML={{
          __html: description
        }}/>
      )
    }
  }
  
  return (
    <Header layout={layout} noMargin={noMargin}>
      {renderImage()}
      <Copy>
        <CopyInner>
          <Title>{title}</Title>
          {renderDescription()}
        </CopyInner>
      </Copy>
    </Header>
  )
}

export default PageHeader
