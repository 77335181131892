import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import AnimationManager from 'components/AnimationManager'
import FlexTitleCtaItem from 'components/modules/FlexTitleCtaItem'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

const CtaSplit = styled.div`
  position: relative;
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${p => p.theme.media.minWidth('tablet')} {
    margin: ${p => p.theme.grid.columnGutter.tablet}px 0;
  }
`

const StyledRow = styled(Row)`
  ${p => p.theme.media.maxWidth('tablet')} {
    margin-left: -2px;
    margin-right: -2px;
  }
`

const StyledCol = styled(Col)`
  ${p => p.theme.media.maxWidth('tablet')} {
    padding-left: 2px;
    padding-right: 2px;
  }
`

const Component = ({ items }) => {
  return (
    <CtaSplit>
      <AnimationManager>
        <Container fullBleedMobile={true}>
          <StyledRow>
            {items.map((item, i) => (
               <StyledCol mb={24} tb={12} key={i}>
                 <div className='u-fade-reveal'>
                   <FlexTitleCtaItem {...item} />
                 </div>
               </StyledCol>
             ))}
          </StyledRow>
        </Container>
      </AnimationManager>
    </CtaSplit>
  )
}

export const query = graphql`
  fragment CtaSplit on ContentfulModuleCtaSplit {
    id
    title
    items {
      ...FlexTitleCtaItem
    }    
  }
`

export default Component
